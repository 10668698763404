<template>
  <div>
    <div class="uk-section uk-padding-remove-vertical uk-margin-remove-vertical in-equity-breadcrumb in-equity-breadcrumb-green">
      <div class="uk-container">
        <div class="uk-grid">
          <div class="uk-width-1-1">
            <ul class="uk-breadcrumb">
              <li><router-link :to="{ name: 'public.home' }">Home</router-link></li>
              <li><span>Signals</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <template v-if="! user.is_subscribed_to_signals && loaded">
      <not-subscribed  :membership_plans="membership_plans" />
    </template>
    <template v-if="user.is_subscribed_to_signals && loaded">
      <subscribed :telegram_link="telegram_link"  :membership_plans="membership_plans" :plan="plan" />
    </template>
    <template v-if="! loaded">
      <div class="uk-text-center uk-margin-large">
        <div class="uk-margin-medium"><img src="/logo.png" alt="logo"></div>
        <div uk-spinner></div>
      </div>
    </template>
  </div>
</template>

<script>
import NotSubscribed from './signals/NotSubscribed.vue';
import Subscribed from './signals/Subscribed.vue';

export default {
  components: {
    NotSubscribed, Subscribed
  }, 

  mounted() {
    this.fetch();

    if(this.user.is_subscribed_to_signals) {
      this.fetchSub()
    }
  },

  data() {
    return {
      membership_plans: { 1: {}, 3: {}, 6: {}, 12: {}},
      plan: {},
      loaded: false,
      telegram_link: ''
    };
  },

  methods: {
    fetch() {
      this.loaded = false

      this.$axios.get("/api/v1/signals-pricing").then((response) => {
        this.membership_plans = response.data.membership_plans;
        this.loaded = true
      });
    },

    fetchSub() {
      this.$axios.get(`/api/v1/guest/signals/subscription`).then(response => {
        this.plan = response.data.plan
        this.telegram_link = response.data.telegram_link
      })
    },
  },

  computed: {
    user() {
      return this.$store.getters.getAuthUser;
    },
  },
};
</script>
