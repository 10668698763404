<template>
  <div>
    <div
      class="
        uk-section
        uk-section-primary
        uk-preserve-color
        uk-background-contain
        uk-background-center
        in-equity-15
      "
      data-src="img/in-equity-15-bg.png"
      data-uk-img=""
      style="background-image: url('/new/in-equity-15-bg.png')"
    >
      <div class="uk-container uk-margin-top uk-margin-medium-bottom">
        <div class="uk-grid">
          <div class="uk-width-3-5@m">
            <h1 class="uk-margin-small-bottom">My Premium Signals Status</h1>
            <a :href="telegram_link" target="_blank" class="uk-button button-telegram uk-button-large uk-width-1-1"><i class="fa fa-telegram"></i> Join Telegram Channel</a>
            <table class="uk-table uk-table-divider uk-background-default uk-text-secondary" style="color: #fff">
              <tbody>
                <tr>
                  <td>Date Joined</td>
                  <td>{{ plan.start }}</td>
                </tr>
                <tr>
                  <td>Duration</td>
                  <td>{{ plan.months }} Month{{ plan.months == 1 ? '' : 's' }}</td>
                </tr>
                <tr>
                  <td>Expiry</td>
                  <td>{{ plan.expiry }}</td>
                </tr>
              </tbody>
            </table>
            <a href="#membership_plans" class="uk-button uk-button-secondary">Extend Your Membership Below</a>
          </div>
        </div>
      </div>
    </div>

    <div class="uk-section in-equity-3 in-offset-top-20">
      <div class="uk-container uk-margin-large-bottom">
        <div class="uk-grid uk-flex uk-flex-middle">
          <div class="uk-width-expand@m">
            <h1 class="uk-margin-small-bottom">
              Extend Your
              <span class="in-highlight">Premium</span> Signals Subscription
            </h1>
            <ul class="uk-list in-list-check">
              <li>1-2 Daily Quality Signals</li>
              <li>Free guidance</li>
              <li>Subscription Based</li>
              <li>Notifications to Email and Private VIP Telegram group</li>
            </ul>
          </div>
          <div class="uk-width-2xlarge uk-flex uk-flex-right uk-flex-center@s">
            <div
              class="
                uk-card uk-card-body uk-card-default uk-border-rounded
                in-margin-top-60@s
              "
            >
              <div id="tradingview-widget">
                <div style="width: 100%">
                  <table class="pricing-table" id="membership_plans">
                    <tbody>
                      <tr>
                        <td
                          style="
                            border-bottom: solid 1px #ddd;
                            border-right: solid 1px #ddd;
                          "
                        >
                          <h3>1 Month</h3>
                          <h1>
                            $ {{ membership_plans[1].whole }}
                            <small>.{{ membership_plans[1].cent }}</small>
                          </h1>
                          <router-link
                            :to="{ name: 'guest.signals.subscribe', params: { id: membership_plans[1].id }}"
                            class="uk-button uk-button-primary uk-button-small uk-margin-remove"
                            >Select</router-link>
                        </td>
                        <td style="border-bottom: solid 1px #ddd">
                          <h3>3 Months</h3>
                          <h1>
                            $ {{ membership_plans[3].whole }}
                            <small>.{{ membership_plans[3].cent }}</small>
                          </h1>
                          <router-link
                            :to="{ name: 'guest.signals.subscribe', params: { id: membership_plans[3].id }}"
                            class="uk-button uk-button-primary uk-button-small uk-margin-remove"
                            >Select</router-link>
                        </td>
                      </tr>
                      <tr>
                        <td style="border-right: solid 1px #ddd">
                          <h3>6 Months</h3>
                          <h1>
                            $ {{ membership_plans[6].whole }}
                            <small>.{{ membership_plans[6].cent }}</small>
                          </h1>
                          <router-link
                            :to="{ name: 'guest.signals.subscribe', params: { id: membership_plans[6].id }}"
                            class="uk-button uk-button-primary uk-button-small uk-margin-remove"
                            >Select</router-link>
                        </td>
                        <td>
                          <h3>12 Months</h3>
                          <h1>
                            $ {{ membership_plans[12].whole }}
                            <small>.{{ membership_plans[12].cent }}</small>
                          </h1>
                          <router-link
                            :to="{ name: 'guest.signals.subscribe', params: { id: membership_plans[12].id }}"
                            class="uk-button uk-button-primary uk-button-small uk-margin-remove"
                            >Select</router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    membership_plans: {
      default() {
        return { 1: {}, 3: {}, 6: {}, 12: {}}
      }
    },
    plan: {
      default() {
        return {}
      }
    },
    telegram_link: {
      default() {
        return ''
      }
    }
  }
}
</script>