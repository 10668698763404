<template>
  <div>
    <div
      class="
        uk-section
        uk-section-primary
        uk-preserve-color
        uk-background-contain
        uk-background-center
        in-equity-15
      "
      data-src="img/in-equity-15-bg.png"
      data-uk-img=""
      style="background-image: url('/new/in-equity-15-bg.png')"
    >
      <div class="uk-container uk-margin-top uk-margin-medium-bottom">
        <div class="uk-grid">
          <div class="uk-width-3-5@m">
            <h1 class="uk-margin-small-bottom">Premium Daily Signals</h1>
            <p class="uk-text-lead uk-margin-remove-top uk-text-bold" style="color: #fff">Community members get signals for free, for one to join the community you must have completed the academy ( 8-week Course)</p>
            <p class="uk-margin-remove">
              <router-link :to="{ name: 'guest.academy' }" class="uk-button uk-button-secondary">Join the course now</router-link>
              <span class="uk-margin-small-right uk-margin-small-left">or</span>
              <a href="#tradingview-widget" class="uk-button uk-button-secondary">Just buy the signals </a>
            </p>
            <div
              class="
                uk-grid-large
                uk-child-width-1-2@m
                uk-child-width-1-2@s
                uk-margin-medium-top
                uk-margin-medium-bottom
                uk-grid
              "
              data-uk-grid=""
            >
              <div class="uk-first-column">
                <div class="uk-flex uk-flex-middle">
                  <div class="uk-margin-right">
                    <img
                      src="/new/in-equity-15-icon-1.svg"
                      data-src="/new/in-equity-15-icon-1.svg"
                      alt="icon-1"
                      width="96"
                      data-height=""
                      data-uk-img=""
                    />
                  </div>
                  <div>
                    <p class="uk-margin-remove uk-text-emphasis">2 Daily Quality Signals</p>
                  </div>
                </div>
              </div>
              <div>
                <div class="uk-flex uk-flex-middle">
                  <div class="uk-margin-right">
                    <img
                      src="/new/in-equity-15-icon-2.svg"
                      data-src="/new/in-equity-15-icon-2.svg"
                      alt="icon-2"
                      width="96"
                      data-height=""
                      data-uk-img=""
                    />
                  </div>
                  <div>
                    <p class="uk-margin-remove uk-text-emphasis">Free guidance</p>
                  </div>
                </div>
              </div>
              <div class="uk-grid-margin uk-first-column">
                <div class="uk-flex uk-flex-middle in-offset-top-20">
                  <div class="uk-margin-right">
                    <img
                      src="/new/in-equity-15-icon-3.svg"
                      data-src="/new/in-equity-15-icon-3.svg"
                      alt="icon-3"
                      width="96"
                      data-height=""
                      data-uk-img=""
                    />
                  </div>
                  <div>
                    <p class="uk-margin-remove uk-text-emphasis">Subscription Based</p>
                  </div>
                </div>
              </div>
              <div class="uk-grid-margin">
                <div class="uk-flex uk-flex-middle in-offset-top-20">
                  <div class="uk-margin-right">
                    <img
                      src="/new/in-equity-15-icon-4.svg"
                      data-src="/new/in-equity-15-icon-4.svg"
                      alt="icon-4"
                      width="96"
                      data-height=""
                      data-uk-img=""
                    />
                  </div>
                  <div>
                    <p class="uk-margin-remove uk-text-emphasis">
                      Notifications To Email and Telegram
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- <hr />
            <div class="uk-flex uk-flex-left uk-flex-middle get-started">
              <a
                href="https://t.me/joinchat/JYgAOwFCVdNkNjE0"
                target="_blank"
                class="
                  uk-button uk-button-secondary uk-margin-left
                "
                >Join the free telegram channel<i
                  class="fas fa-arrow-circle-right uk-margin-small-left"
                ></i
              ></a>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="uk-section in-equity-3 in-offset-top-20">
      <div class="uk-container uk-margin-large-bottom">
        <div class="uk-grid uk-flex uk-flex-middle">
          <div class="uk-width-expand@m">
            <h1 class="uk-margin-small-bottom">
              Our Premium
              <span class="in-highlight">Signals</span> Plans
            </h1>
            <ul class="uk-list in-list-check">
              <li>1-2 Daily Quality Signals</li>
              <li>Free guidance</li>
              <li>Subscription Based</li>
              <li>Notifications to Email and Private VIP Telegram group</li>
            </ul>
          </div>
          <div class="uk-width-2xlarge uk-flex uk-flex-right uk-flex-center@s">
            <div
              class="
                uk-card uk-card-body uk-card-default uk-border-rounded
                in-margin-top-60@s
              "
            >
              <div id="tradingview-widget">
                <div style="width: 100%">
                  <table class="pricing-table">
                    <tbody>
                      <tr>
                        <td
                          style="
                            border-bottom: solid 1px #ddd;
                            border-right: solid 1px #ddd;
                          "
                        >
                          <h3>1 Month</h3>
                          <h1>
                            $ {{ membership_plans[1].whole }}
                            <small>.{{ membership_plans[1].cent }}</small>
                          </h1>
                          <router-link
                            :to="{ name: 'guest.signals.subscribe', params: { id: membership_plans[1].id }}"
                            class="uk-button uk-button-primary uk-button-small uk-margin-remove"
                            >Select</router-link>
                        </td>
                        <td style="border-bottom: solid 1px #ddd">
                          <h3>3 Months</h3>
                          <h1>
                            $ {{ membership_plans[3].whole }}
                            <small>.{{ membership_plans[3].cent }}</small>
                          </h1>
                          <router-link
                            :to="{ name: 'guest.signals.subscribe', params: { id: membership_plans[3].id }}"
                            class="uk-button uk-button-primary uk-button-small uk-margin-remove"
                            >Select</router-link>
                        </td>
                      </tr>
                      <tr>
                        <td style="border-right: solid 1px #ddd">
                          <h3>6 Months</h3>
                          <h1>
                            $ {{ membership_plans[6].whole }}
                            <small>.{{ membership_plans[6].cent }}</small>
                          </h1>
                          <router-link
                            :to="{ name: 'guest.signals.subscribe', params: { id: membership_plans[6].id }}"
                            class="uk-button uk-button-primary uk-button-small uk-margin-remove"
                            >Select</router-link>
                        </td>
                        <td>
                          <h3>12 Months</h3>
                          <h1>
                            $ {{ membership_plans[12].whole }}
                            <small>.{{ membership_plans[12].cent }}</small>
                          </h1>
                          <router-link
                            :to="{ name: 'guest.signals.subscribe', params: { id: membership_plans[12].id }}"
                            class="uk-button uk-button-primary uk-button-small uk-margin-remove"
                            >Select</router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    
  </div>
</template>

<script>
export default {
  props: {
    membership_plans: {
      default() {
        return { 1: {}, 3: {}, 6: {}, 12: {}}
      }
    }
  }
}
</script>